<template>
  <div>
    <a-table
      :columns="columns"
      :data-source="datasource"
      rowKey="id"
      :pagination="pagination"
      @change="handleTableChange"
    >
      <template slot="select" slot-scope="text, record">
        <p v-if="record.state == 1">已激活</p>
        <p v-if="record.state == 2">未激活</p>
        <p v-if="record.state == 3">已离职</p>
      </template>
    </a-table>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { getCompanyUsersAsync } from "@/api/SaasAdmin/company.js";
/**公司成员列表列配置 */
const columns = [
  {
    title: "工号",
    dataIndex: "employeeNo",
  },
  {
    title: "姓名",
    dataIndex: "fullName",
  },
  {
    title: "手机号",
    dataIndex: "phoneNumber",
  },
  {
    title: "角色",
    dataIndex: "companyUserRoleName",
  },
  {
    title: "邀请状态",
    dataIndex: "invitateStateName",
    align: "center",
  },
  {
    title: "状态",
    dataIndex: "activeStateName",
    align: "center",
  },
];
export default {
  name: "SaasAdminCompanyMembers",
  props: {
    readonly: { type: Boolean, default: () => false },
    form: { type: Object, default: () => {} },
  },
  data() {
    return {
      columns, //公司成员列表列
      datasource: [], //公司成员列表数据源
      /** 公司成员列表分页参数设置 */
      pagination: {
        pageSize: 4,
        total: 0,
        current: 1,
      },
    };
  },
  created() {
    if (this.form.id) {
      this.whenSearch();
    } else {
      this.datasource = [];
    }
  },
  methods: {
    /**
     * 公司成员列表分页数变更事件
     * @param {object}  pagination [分页器事件]
     * */
    handleTableChange(pagination) {
      const pager = { ...this.pagination };
      pager.current = pagination ? pagination.current : pager.current;
      this.pagination = pager;
      this.loadDataAsync();
    },
    loadDataAsync() {
      const params = {
        companyId: this.form.id,
        filter: this.filter,
        skipCount: (this.pagination.current - 1) * this.pagination.pageSize,
        maxResultCount: this.pagination.pageSize,
      };
      this.loading = true;
      getCompanyUsersAsync(params)
        .then((res) => {
          this.datasource = res.items;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$message.error("公司列表查询异常");
        });
    },
    whenSearch() {
      this.pagination.current = 1;
      this.loadDataAsync();
    },
  },
  watch: {
    form: {
      handler() {
        this.created();
      },
    },
  },
};
</script>
