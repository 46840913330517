<template>
  <a-modal
    title="公司详情"
    :footer="null"
    :visible="visible"
    :destroyOnClose="true"
    @ok="handleOk"
    @cancel="$emit('cancel')"
    width="800px"
  >
    <a-form-model
      class="formInfo"
      ref="formData"
      layout="horizontal"
      :model="formData"
      labelAlign="left"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 20 }"
    >
      <a-form-model-item label="公司名称" prop="name">
        <a-input
          v-model="formData.name"
          placeholder="公司名称"
          :disabled="readonly"
        />
      </a-form-model-item>
      <a-form-model-item label="行业类型" prop="companyIndustryCodeId">
        <a-select v-model="formData.companyIndustryCodeId" :disabled="readonly">
          <a-select-option
            v-for="(item, index) of dicts.industryType"
            :value="item.id"
            :key="index"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="企业规模" prop="companyStaffCodeId">
        <a-select v-model="formData.companyStaffCodeId" :disabled="readonly">
          <a-select-option
            v-for="(item, index) of dicts.staffType"
            :value="item.id"
            :key="index"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="公司地址" prop="addressDetail">
        <a-input
          v-model="formData.addressDetail"
          placeholder="公司地址"
          :disabled="readonly"
        />
      </a-form-model-item>
      <!--
      <a-form-model-item label="营业执照">
        <img src="@/assets/img/loginLogo.png" />
      </a-form-model-item>
      -->
      <a-form-model-item :wrapper-col="{ span: 24 }">
        <div>
          <p>公司成员</p>
          <a-divider />
          <SaasCompanyMembers
            ref="saasCompanyMembers"
            :form="form"
            :readonly="readonly"
          />
        </div>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { getDowndownAsync } from "@/api/code.js";
import { getCompanyAsync } from "@/api/SaasAdmin/company.js";
import SaasCompanyMembers from "./Members.vue";
const defaultFormData = {
  name: "",
  companyStaffCodeId: "",
  companyIndustryCodeId: "",
};
export default {
  components: { SaasCompanyMembers },
  props: {
    visible: { type: Boolean, default: () => false },
    readonly: { type: Boolean, default: () => false },
    form: { type: Object, default: () => {} },
  },
  data() {
    return {
      dicts: {
        industryType: [],
        staffType: [],
      },
      formData: { ...defaultFormData },
    };
  },
  created() {
    this.loadDicts();
  },
  methods: {
    /**
     * 显示组件
     * @param {obj} row [表单数据源]
     */
    show(row) {
      this.formData = row;
      this.visible = true;
    },
    /**  */
    handleOk() {
      this.visible = true;
    },
    loadDicts() {
      getDowndownAsync({
        parentCodeKeyPath: "Root_ServiceIndustry",
      }).then((res) => {
        this.dicts.industryType = res.items;
      });

      getDowndownAsync({
        parentCodeKeyPath: "Root_CompanyStaff",
      }).then((res) => {
        this.dicts.staffType = res.items;
      });
    },
  },
  watch: {
    form: {
      handler() {
        if (this.form.id) {
          getCompanyAsync(this.form.id).then((res) => {
            this.formData = res;
          });
        } else {
          this.formData = Object.assign({}, defaultFormData);
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
