/* eslint-disable no-unused-vars */
import { getRequest, postRequest } from "@/utils/http";

/**
 * 查询用户的所有关联公司集合
 * @param {object} params
 * */
export const getCompanyListAsync = (params) =>
  getRequest(`/identity/saasCompany`, params);

/**
 * 查询公司
 * @param {Guid} id 公司Id
 * @returns Promise
 */
export const getCompanyAsync = (id) =>
  getRequest(`/identity/saasCompany/${id}`);

/**
 * 获取公司用户
 * @param {*} params 参数
 * @returns Promise
 */
export const getCompanyUsersAsync = (params) =>
  getRequest(`/identity/saasCompany/users`, params);
