<template>
  <div class="bgStyle">
    <div>研发公司管理</div>
    <div>
      <a-space size="8">
        <a-input-search
          v-model="filter"
          placeholder="公司名称"
          style="width: 200px"
          @search="onSearch"
        />
      </a-space>
    </div>
    <div>
      <a-table
        :columns="columns"
        :data-source="datasource"
        rowKey="id"
        :pagination="pagination"
        @change="handleTableChange"
        :loading="loading"
      >
        <template slot="action" slot-scope="text, record">
          <a @click="whenView(record)">查看</a>
        </template>
      </a-table>
    </div>
    <SaasCompanyDetails
      :visible="editCtrl.visible"
      :form="editCtrl.form"
      :readonly="editCtrl.readonly"
      @cancel="editCtrl.visible = false"
    />
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import SaasCompanyDetails from "./components/Details";
import { getCompanyListAsync } from "@/api/SaasAdmin/company.js";
/**数据字典列表列配置 */
const columns = [
  {
    title: "公司名称",
    dataIndex: "name",
  },
  {
    title: "超级管理员",
    dataIndex: "managerName",
  },
  {
    title: "手机号",
    dataIndex: "managerPhone",
  },
  {
    title: "团队成员数量",
    dataIndex: "staffCodeName",
  },
  {
    title: "行业类型",
    dataIndex: "industryCodeName",
  },
  {
    title: "详细地址",
    dataIndex: "addressDetail",
  },
  {
    title: "操作",
    dataIndex: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];

export default {
  components: { SaasCompanyDetails },
  data() {
    return {
      columns, //公司管理列表列
      datasource: [], //公司管理列表数据源
      /** 公司管理列表分页参数设置 */
      pagination: {
        pageSize: 10,
        total: 0,
        current: 1,
      },
      editCtrl: {
        visible: false,
        readonly: false,
        form: {},
      },
      filter: undefined,
    };
  },
  /** 组件生命周期创建前事件 */
  created() {
    this.onSearch();
  },
  methods: {
    /**
     * 公司名称查询框查询事件
     */
    onSearch() {
      this.pagination.current = 1;
      this.loadDataAsync();
    },
    /**
     * 公司管理列表行按钮（查看）单击事件
     * @param {Object} row [用户列表单击行]
     */
    whenView(row) {
      var entity = JSON.parse(JSON.stringify(row));
      this.editCtrl.readonly = true;
      this.editCtrl.form = entity;
      this.editCtrl.visible = true;
    },
    /**
     * 公司管理列表页数变更事件
     * @param {Object} pagination [分页器对象]
     */
    handleTableChange(pagination) {
      const pager = { ...this.pagination };
      pager.current = pagination ? pagination.current : pager.current;
      this.pagination = pager;
      this.loadDataAsync();
    },
    loadDataAsync() {
      var parm = {
        filter: this.filter,
        skipCount: (this.pagination.current - 1) * this.pagination.pageSize,
        maxResultCount: this.pagination.pageSize,
      };
      this.loading = true;
      getCompanyListAsync(parm)
        .then((res) => {
          this.pagination.total = res.totalCount;
          this.datasource = res.items;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$message.error("公司列表查询异常");
        });
    },
  },
};
</script>
